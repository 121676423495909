/** @jsx jsx */
import ProductTemplate from '@bottlebooks/gatsby-theme-event/src/templates/ProductTemplate';
import { graphql } from 'gatsby';

export default ProductTemplate;

export const pageQuery = graphql`
  query custom_ProductTemplate(
    $id: String!
    $productId: ID!
    $eventId: ID!
    $exhibitorId: ID!
    $locale: Bottlebooks_ContentLocale
    $previousId: String
    $nextId: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        eventId
        # Get the IDs and other data out of registeredProducts.
        registeredProducts(
          filter: {
            productId: { eq: $productId }
            partnerId: { eq: $exhibitorId }
          }
        ) {
          nodes {
            ...EventProductPage_RegisteredProduct
            ...custom_ProductActions
            ...custom_ProductSections
            product {
              ...custom_ProductRegion
            }
          }
        }
        registrations(filter: { companyId: { eq: $exhibitorId } }) {
          nodes {
            # This is a quick fix to get the data onto the page
            ...bb_Registration
          }
        }
        product(productId: $productId, exhibitorId: $exhibitorId) {
          ...bb_EventProductPage

          ... on Bottlebooks_Wine {
            wineType(format: LOCALIZED)
          }
        }
      }
    }
    product(id: { eq: $id }) {
      id
      ...EventProductPage
      producer {
        name
        products {
          ...EventProductPage_Related
        }
      }
    }
    previous: product(id: { eq: $previousId }) {
      ...ChangeProductLink
    }
    next: product(id: { eq: $nextId }) {
      ...ChangeProductLink
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
